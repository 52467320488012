import React from 'react'
import { useDeployments } from '../../../api/deployments'
import DeploymentsList from '../list/deployments_list'
import SimplifiedCard from '../../simplified_card'
import { Typography } from '@mui/material'

const LatestDeployments = ({ username }) => {
  const { loading, deployments } = useDeployments({ username, limit: 5 })
  const title = loading ? 'Last X deployments' : `Last ${deployments.length} deployments`

  return (
    <SimplifiedCard title={title} noExtraBottomPadding>
      <DeploymentsList loading={loading} deployments={deployments} expected={5} />
      {deployments.length === 0 && (
        <Typography variant='body2' color='textSecondary'>
          You don't have any deployments
        </Typography>
      )}
    </SimplifiedCard>
  )
}

export default LatestDeployments
