import { ApolloClient, InMemoryCache, createHttpLink, from, ApolloLink } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { enqueueSnackbar } from 'notistack'
import { createConsumer, logger } from '@rails/actioncable'
import ActionCableLink from 'graphql-ruby-client/subscriptions/ActionCableLink'

logger.enabled = true

// import { setContext } from '@apollo/client/link/context'
// import getCsrfToken from "../utils/csrf_token"

export const cable = createConsumer()

const hasSubscriptionOperation = ({ query: { definitions } }) => {
  return definitions.some(({ kind, operation }) => kind === 'OperationDefinition' && operation === 'subscription')
}

const baseHttpLink = createHttpLink({ uri: '/api', credentials: 'include' })

// This is causing issues, disabling for now, may revisit this decision in the future
//
// const csrfTokenLink = setContext((_, { headers }) => ({
//   headers: {
//     ...headers,
//     "X-CSRF-TOKEN": getCsrfToken(),
//   },
// }))

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message }) => enqueueSnackbar(message, { variant: 'error', preventDuplicate: true }))
  } else if (networkError) {
    enqueueSnackbar(`[Network error]: ${networkError}`, { variant: 'error', preventDuplicate: true })
  }
})

const httpLink = from([errorLink, baseHttpLink])
const link = ApolloLink.split(
  hasSubscriptionOperation,
  new ActionCableLink({
    cable,
    onReconnect: () => {
      console.log('WS reconnected: triggering reFetchObservableQueries')
      client.reFetchObservableQueries()
    }
  }),
  httpLink
)

// link: from([errorLink, csrfTokenLink.concat(httpLink)]),
const client = new ApolloClient({
  link,
  cache: new InMemoryCache()
})

export default client
