import React from 'react'
import { IconButton, Typography, Link } from '@mui/material'
import OpenInNew from 'mdi-material-ui/OpenInNew'
import CountryIcon from '../country_icon'
import { testingInstanceUrl } from '../../utils/urls'
import styled from '../../utils/styled'
import InstanceLinks from '../instance_links'

const Header = styled('div')`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: ${(props) => props.theme.spacing(2)};
  border-bottom: 1px solid ${(props) => props.theme.palette.divider};
`

const NameContainer = styled('div')`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
`

const InstanceName = styled('div')`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
`

const InstanceCountryIcon = styled(CountryIcon).attrs(() => ({ height: 20 }))`
  margin-right: ${(props) => props.theme.spacing(1)};
`

const Name = ({ name, country, withLinks, children }) => {
  if (!country) {
    return (
      <NameContainer>
        {name}
        {String(Number(name)) === name && (
          <Typography variant='body2' sx={{ marginLeft: 0.5 }}>
            (legacy)
          </Typography>
        )}
      </NameContainer>
    )
  }

  const props = withLinks ? { as: Link, href: `/instances/${name}`, underline: 'none', color: 'inherit' } : {}
  const number = name.split('-')[2]

  return (
    <NameContainer>
      <InstanceName {...props}>
        <InstanceCountryIcon country={country} />
        <span>{name}</span>
      </InstanceName>
      {withLinks && !!number && <InstanceLinks country={country} number={number} />}
    </NameContainer>
  )
}

const InstanceCardHeader = ({ name, country, number, showOpenButton, status, withLinks = false }) => (
  <Header>
    <Name name={name} country={country} withLinks={withLinks} />
    {!!status && <div>{status}</div>}
    {showOpenButton && (
      <div>
        <IconButton size='small' component='a' href={testingInstanceUrl(country, number)} target='_blank'>
          <OpenInNew />
        </IconButton>
      </div>
    )}
  </Header>
)

export default InstanceCardHeader
