import React from 'react'
import Button from '@mui/material/Button'
import { testingInstanceUrl } from '../utils/urls'
import styled from '../utils/styled'

const Actions = styled('div')`
  display: flex;
  align-items: center;
  gap: ${(props) => props.theme.spacing(1)};
`

const InstanceLinks = ({ country, number }) => (
  <Actions>
    <Button color='info' size='small' href={testingInstanceUrl(country, number)} target='_blank'>
      WWW
    </Button>
    <Button color='info' size='small' href={testingInstanceUrl(country, number, 'admin')} target='_blank'>
      Admin
    </Button>
  </Actions>
)

export default InstanceLinks
