import React from 'react'
import { Container, Grid, Paper, useMediaQuery } from '@mui/material'
import { useParams, useLocation } from 'react-router-dom'
import { useInstance } from '../../../api/instances'
import Title from '../../title'
import { TEAL_1 } from '../../../constants/brand'
import FakeInstance from '../instances/fake_instance'
import reverse from 'lodash/reverse'
import { useTheme } from '@mui/material/styles'
import groupBy from 'lodash/groupBy'
import sortBy from 'lodash/sortBy'
import { useConfig } from '../../../config'
import AppVersionsCard from './cards/app_versions'
import InstanceStatusCard from './cards/instance_status'
import ActiveReservationCard from './cards/active_reservation'
import RecentReservationsCard from './cards/recent_reservations'
import DatabaseCard from './cards/database'

const InstancePage = () => {
  const { name } = useParams()
  const location = useLocation()
  const theme = useTheme()
  const config = useConfig()
  const condensedLayout = useMediaQuery(theme.breakpoints.down('md'))

  const initialInstanceData = location.state?.initialInstanceData

  const { instance: instanceFromSubscription, loading: loadingInBackground } = useInstance({ name })

  const partiallyLoaded = !!initialInstanceData && loadingInBackground
  const loading = !initialInstanceData && loadingInBackground
  const instance = instanceFromSubscription || initialInstanceData

  if (loading) {
    return (
      <Container>
        <Title title={`Testing Instances - ${name}`} colors={[TEAL_1, TEAL_1]} />
        <FakeInstance />
      </Container>
    )
  }

  // TODO: proper 404 page
  if (!loading && !instance) {
    return (
      <Container>
        <Title title={`Testing Instances - ${name}`} colors={[TEAL_1, TEAL_1]} />
        <Paper sx={{ padding: 2, textAlign: 'center' }}>Instance not found</Paper>
      </Container>
    )
  }

  const reservations = reverse(instance.reservations?.edges?.map(({ node }) => node) || [])
  const builds = (instance.latestJenkinsBuilds || []).concat(instance.latestSemaphoreBuilds || [])
  const activeReservation = reservations.find((reservation) => !!reservation.active)

  const availableApps = Object.keys(config.apps)
  const filteredBuilds = builds.filter(({ app }) => availableApps.includes(app))
  const apps = groupBy(sortBy(filteredBuilds, 'app'), 'app')

  return (
    <Container>
      <Title title={`Testing Instances - ${name}`} colors={[TEAL_1, TEAL_1]} />

      <Grid container sx={{ mb: 2, justifyContent: 'stretch' }} spacing={2}>
        <Grid item xs={12} lg={4}>
          <InstanceStatusCard instance={instance} />
        </Grid>
        <Grid item xs={12} lg={4}>
          <DatabaseCard instance={instance} />
        </Grid>
        <Grid item xs={12} lg={4}>
          <ActiveReservationCard
            partiallyLoaded={partiallyLoaded}
            instance={instance}
            activeReservation={activeReservation}
          />
        </Grid>
      </Grid>

      <Grid container sx={{ mb: 2 }} spacing={2}>
        <Grid item xs={12} md={8} sx={{ order: condensedLayout ? 2 : 1 }}>
          <AppVersionsCard apps={apps} instance={instance} />
        </Grid>

        <Grid item xs={12} md={4} sx={{ order: condensedLayout ? 1 : 2 }}>
          <RecentReservationsCard partiallyLoaded={partiallyLoaded} reservations={reservations} />
        </Grid>
      </Grid>
    </Container>
  )
}

export default InstancePage
