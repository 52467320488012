import { gql, useQuery } from '@apollo/client'
import { useEffect } from 'react'

export const DEPLOYMENT_CREATE = gql`
  mutation deploymentCreate(
    $username: String!
    $jiraIssues: [String!]
    $jenkinsBuilds: [JenkinsBuildInput!]
    $semaphoreBuilds: [SemaphoreBuildInput!]
    $reservation: ReservationInput
  ) {
    deploymentCreate(
      username: $username
      jiraIssues: $jiraIssues
      jenkinsBuilds: $jenkinsBuilds
      semaphoreBuilds: $semaphoreBuilds
      reservation: $reservation
    ) {
      deployment {
        id
      }
    }
  }
`

export const FETCH_DEPLOYMENTS = gql`
  query deployments($username: String, $page: Int, $limit: Int) {
    deployments(username: $username, page: $page, limit: $limit) {
      nodes {
        id
        username
        jiraIssues {
          key
          title
          description
          priority
          status
          team
          url
        }
        status
        builds {
          nodes {
            instance {
              name
            }
          }
        }
        createdAt
        updatedAt
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      totalCount
    }
  }
`

export const FETCH_DEPLOYMENT = gql`
  query deployments($id: ID!) {
    deployment(id: $id) {
      id
      username
      jiraIssues {
        key
        title
        description
        priority
        status
        team
        url
      }
      status
      builds {
        nodes {
          app
          appType
          progress
          instance {
            name
            country
            number
          }
          revision
          status
          error
          buildUrl
          appUrl
          ... on JenkinsBuild {
            detailsUrl
          }
        }
      }
      createdAt
    }
  }
`

export const DEPLOYMENT_CREATED_SUBSCRIPTION_NO_DETAILS = gql`
  subscription deploymentCreated {
    deploymentCreated {
      deployment {
        id
      }
    }
  }
`

export const DEPLOYMENT_UPDATED_SUBSCRIPTION = gql`
  subscription deploymentUpdated($id: ID!) {
    deploymentUpdated(id: $id) {
      build {
        app
        appType
        progress
        instance {
          name
          country
          number
        }
        revision
        status
        error
        buildUrl
        appUrl
        ... on JenkinsBuild {
          detailsUrl
        }
      }
    }
  }
`

export const useDeployments = ({ username, limit, ...opts } = {}) => {
  const { data, subscribeToMore, refetch, ...rest } = useQuery(FETCH_DEPLOYMENTS, {
    variables: { username, limit },
    ...opts
  })

  useEffect(
    () =>
      subscribeToMore({
        document: DEPLOYMENT_CREATED_SUBSCRIPTION_NO_DETAILS,
        updateQuery: (prev, { subscriptionData }) => {
          if (subscriptionData.data.deploymentCreated?.deployment) {
            refetch()
          }

          return prev
        }
      }),
    []
  )

  const deployments = data?.deployments?.nodes || []
  return { deployments, refetch, ...rest }
}
